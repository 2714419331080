body {
  margin: 0;
  height: 100%;
  background-color: #E4002B;
}

.container {
		display: flex;
		justify-content: center;
		align-items: center;
    min-height: calc(100vh);
    background-color: #E4002B;
}
