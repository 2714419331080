@font-face {
    font-family: 'RiftBoldItalic';
    src: url('Copyright-Fort-Foundry.eot');
    src: url('Copyright-Fort-Foundry.eot?#iefix') format('embedded-opentype'),
        url('Copyright-Fort-Foundry.woff2') format('woff2'),
        url('Copyright-Fort-Foundry.woff') format('woff'),
        url('Copyright-Fort-Foundry.ttf') format('truetype'),
        url('Copyright-Fort-Foundry.svg#Copyright-Fort-Foundry') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'RiftBold';
    src: url('Rift-Bold.woff2') format('woff2'),
        url('Rift-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RiftRegular';
    src: url('Rift-Regular.woff2') format('woff2'),
        url('Rift-Regular.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
